import React from 'react'
import { Link } from 'gatsby'
import './announcementBanner.styles.scss';

const AnnouncementBanner = () => {
    return (
        <aside className="announcement-banner">
            <p>HOLIDAY50 at checkout for 50% off <Link style={{fontSize: "0.9rem", textDecoration: 'underline'}} to="/shop/applecore-variety-pack/">Variety Pack</Link></p>
        </aside>
    )
}

export default AnnouncementBanner;