import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"

function Seo({ description, lang, title, meta, thumbnailImage, addedKeywords = '', url }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            keywords
            defaultImage
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const keywords = site.siteMetadata.keywords;
  // const siteUrl = url || site.siteMetadata.siteUrl
  const image = thumbnailImage || site.siteMetadata.defaultImage;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={`${title} | Applecore Cord Organizer`}
      // titleTemplate={`%s | ${site.siteMetadata.title}`}
      keywords={keywords}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: `${title} | Applecore Cord Organizer`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: url
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `keywords`,
          content: `${keywords}, ${addedKeywords}`
        },
        {
          property: `og:image`,
          content: image
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: `${title} | Applecore Cord Organizer`,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
      >
        <meta name="facebook-domain-verification" content="4ur3j2v4qgissu91h8w4jlaf5n3mhp" />
        <link rel="preload" as="font" type="font/woff2" href="/fonts/merriweather-700.woff2" crossorigin />
        <link rel="preload" as="font" type="font/woff2" href="/fonts/fira-sans-regular.woff2" crossorigin />
        <link rel="preload" as="font" type="font/woff2" href="/fonts/fira-sans-600.woff2" crossorigin />
      </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo

